import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import CompanyQuote from '../../../components/CompanyQuote';
import Layout from '../../../components/Layout';
import Button from '../../../components/Button';
import HeaderImage from '../../../assets/cloudfront/cloudfront-main.jpg';
import { getSeoConfig } from '../../../constants/defaultSeoProps';
import s from './CloudFrontSolutions.module.scss';

const propTypes = {
  details: PropTypes.shape({
    title: PropTypes.string.isRequired,
    headerParagraph: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    whyChooseTitle: PropTypes.string.isRequired,
    contactUsContent: PropTypes.string.isRequired,
    cloudFrontSections: PropTypes.array.isRequired,
    whyChooseCloudFront: PropTypes.array.isRequired,
    contactUsSummary: PropTypes.string.isRequired,
  }).isRequired,
  companyQuote: PropTypes.shape({
    title: PropTypes.string,
    quote: PropTypes.string,
    footerDescription: PropTypes.shape({}).isRequired,
    icons: PropTypes.array.isRequired,
  }).isRequired,
};

const seoParams = {
  ogImagePath: '/og-images/cloudFront.png',
  ogTitle: 'CloudFront solutions',
  ogDesc:
    'Tech Holding leverages Amazon CloudFront to accelerate your digital transformation with high-performance content delivery and powerful edge computing capabilities. Our comprehensive CloudFront solutions ensure that your applications are fast, secure, and globally accessible.',
  ogPath: '/services/aws/cloudfront-solutions',
};

const updatedSeo = getSeoConfig(seoParams);

const CloudFrontSolutions = ({ details, companyQuote }) => {
  const {
    title,
    headerParagraph,
    whyChooseTitle,
    description,
    cloudFrontSections,
    whyChooseCloudFront,
  } = details;
  return (
    <Layout
      currentPage="/services/aws/cloudfront-solutions"
      seo={updatedSeo}
      title={updatedSeo.openGraph.title}
      footerDescription={companyQuote.footerDescription}
    >
      <div className={s.headerWrapper}>
        <div className={s.headerContainer} data-test="headerContainer">
          <div className={s.title}>
            <h2 data-test="title">{title}</h2>
            <p>{headerParagraph}</p>
            <div className={s.button}>
              <Link to={`/work`}>
                <Button theme="grey">See Our Work</Button>
              </Link>
            </div>
          </div>
          <div className={s.image} data-test="cloudFrontPartnerNetworkLogo">
            <img className={s.emoji} src={HeaderImage} alt="CloudFront Partner Network" />
          </div>
        </div>
      </div>
      <div className={s.pageContainer} data-test="pageContainer">
        <div className={s.whyChoose}>
          <h2>{description}</h2>
          <div className={s.cloudFrontSectionsContainer}>
            {cloudFrontSections.map((cloudFrontSection) => (
              <div
                className={s.cloudFrontSections}
                key={cloudFrontSection.title}
                data-test="cloudFrontSections"
              >
                <div className={s.content}>
                  <h3>{cloudFrontSection.title}</h3>
                  <p>{cloudFrontSection.summary}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={s.whyChoose}>
          <h2>{whyChooseTitle}</h2>
          <div className={s.cloudFrontSectionsContainer}>
            {whyChooseCloudFront.map((cloudFrontSection) => (
              <div
                className={s.cloudFrontSections}
                key={cloudFrontSection.title}
                data-test="whyChooseCloudFront"
              >
                <div className={s.content}>
                  <h3>{cloudFrontSection.title}</h3>
                  <p>{cloudFrontSection.summary}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <CompanyQuote companyQuote={companyQuote} />
    </Layout>
  );
};

CloudFrontSolutions.propTypes = propTypes;
export default CloudFrontSolutions;
