import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import CloudFrontSolutions from '../../containers/Services/CloudFrontSolutions';

const cloudFrontSections = [
  {
    title: 'Fast, Secure Content Delivery',
    summary:
      'We optimize the delivery of your static and dynamic web content using Amazon CloudFront. Websites and applications hosted on CloudFront benefit from reduced load times and increased security, enhancing user satisfaction and engagement.',
  },
  {
    title: 'Edge Computing with Lambda@Edge',
    summary:
      'Enhance your applications by bringing custom logic closer to your users. Lambda@Edge allows you to run serverless functions across AWS locations worldwide, responding to end-users with the lowest latency. This is ideal for SEO, personalization, content routing, and more, without managing infrastructure.',
  },
  {
    title: 'CloudFront Functions for Lightweight Processing',
    summary:
      'For lightweight web transformations, CloudFront Functions enables you to execute small pieces of code at AWS edge locations. This is optimized for high-scale, latency-sensitive operations, allowing you to easily manipulate HTTP(S) request and response parameters to customize the content delivered through CloudFront.',
  },
];

const whyChooseCloudFront = [
  {
    title: 'Enhanced Performance',
    summary:
      'Our configurations are designed to reduce latency and improve data transfer speeds, ensuring that your content is delivered quickly and reliably to a global audience.',
  },
  {
    title: 'Cost-Effective',
    summary:
      'With Amazon CloudFront, you pay only for what you use. Tech Holding helps you optimize your usage to keep costs low while maximizing content delivery effectiveness.',
  },
  {
    title: 'Security at Scale',
    summary:
      'Amazon CloudFront provides advanced security features, including SSL/TLS encryption and integration with AWS Shield, AWS WAF, and Amazon Route 53 to safeguard against multiple types of attacks including DDoS, application, and network layer attacks.',
  },
  {
    title: 'Deep AWS Integration',
    summary:
      'Seamless integration with other AWS services like Amazon S3, EC2, Elastic Load Balancing, and Lambda@Edge, enhancing functionality and simplifying operations.',
  },
  {
    title: 'Global Reach',
    summary:
      'With servers located in multiple geographical locations, your content is cached closer to end-users, improving accessibility and speed.',
  },
];

const title = 'CloudFront solutions';
const headerParagraph =
  'Tech Holding leverages Amazon CloudFront to accelerate your digital transformation with high-performance content delivery and powerful edge computing capabilities. Our comprehensive CloudFront solutions ensure that your applications are fast, secure, and globally accessible.';

const whyChooseTitle = 'Benefits of Using Amazon CloudFront through Tech Holding';

const description = 'Our CloudFront Offering';

const details = {
  title,
  headerParagraph,
  whyChooseTitle,
  description,
  cloudFrontSections,
  whyChooseCloudFront,
};

const propTypes = {
  data: PropTypes.shape({
    companyQuote: PropTypes.shape({
      title: PropTypes.string,
      quote: PropTypes.string,
      footerDescription: PropTypes.shape({}).isRequired,
      icons: PropTypes.array.isRequired,
    }),
  }).isRequired,
};

const CloudFrontPage = ({ data }) => (
  <CloudFrontSolutions details={details} companyQuote={data.companyQuote} />
);
CloudFrontPage.propTypes = propTypes;

export default CloudFrontPage;

export const query = graphql`
  query {
    companyQuote: contentfulCompanyQuote(label: { eq: "servicesAws" }) {
      title
      quote
      footerDescription {
        json
      }
      icons {
        fluid(quality: 100, maxWidth: 1000) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
  }
`;
